<template lang="">
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus
            :title="
              $t(
                'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.headline'
              )
            "
            :hide-plus="true"
          />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col gap-2 my-1 md:items-center md:flex-row px-5"
      ></div>

      <FSTable
        :fst-id="`marketplaceBuyerOrderIndex`"
        :searchEnabled="false"
        :headers="getTableHeaders"
        :endpoint="getEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <template v-for="(item, itemIndex) in data">
            <template v-if="getTableMode === 'full'">
              <FSTableRow :key="itemIndex" text-fallback-always>
                <FSTableRowItem :text="item.id" :truncate="-5" />

                <FSTableRowItem
                  :text="
                    getFormattedDateTime(
                      dateZuluFormatter(item.order_date),
                      'D MMM YYYY'
                    )
                  "
                />
                <FSTableRowItem :text="item.number_of_vehicles_to_buy" />
                <FSTableRowItem
                  :text="
                    `${item.marketplace_fleet.fleet.country.currency_symbol} ${item.order_amount}`
                  "
                />

                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatus(item.order_status)"
                    :variant="getItemVariant(item.order_status)"
                    profile="payment"
                  />
                </FSTableRowItem>
                <FSTableRowItem>
                  <MoreActions
                    :key="`more-actions-${itemIndex}`"
                    :data="item"
                    @delete="$store.dispatch('fsTable/fetchData')"
                  />
                </FSTableRowItem>
              </FSTableRow>
            </template>
            <template v-if="getTableMode === 'responsive'">
              <FSTableRow :key="`fs-table-row-${itemIndex}`" textFallbackAlways>
                <FSTableRowItem>
                  <div
                    class="col-span-1 focus:text-gray-400"
                    @click="toggle(itemIndex)"
                  >
                    <i
                      class="fas fa-minus-circle"
                      style="color:#d90a20;"
                      v-if="opened.includes(itemIndex)"
                    ></i>
                    <i class="fas fa-plus-circle" v-else></i>
                  </div>
                </FSTableRowItem>
                <FSTableRowItem :text="item.id" :truncate="-5" />
                <FSTableRowItem :text="item.number_of_vehicles_to_buy" />
                <FSTableRowItem
                  :text="
                    `${item.marketplace_fleet.fleet.country.currency_symbol} ${item.order_amount}`
                  "
                />
                <FSTableRowItem>
                  <XStatus
                    :text="getItemStatus(item.order_status)"
                    :variant="getItemVariant(item.order_status)"
                    profile="payment"
                  />
                </FSTableRowItem>
              </FSTableRow>
              <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                <td colspan="10">
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.transactionTime'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      {{
                        getFormattedDateTime(
                          dateZuluFormatter(item.order_date),
                          'D MMM YYYY'
                        )
                      }}
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-10 items-center w-full expand-item"
                  >
                    <div class="col-span-5 left-text">
                      {{
                        $t(
                          'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.action'
                        )
                      }}
                    </div>
                    <div class="col-span-5 right-text">
                      <MoreActions
                        :key="`more-actions-${itemIndex}`"
                        :data="item"
                        @delete="$store.dispatch('fsTable/fetchData')"
                      />
                    </div>
                  </div>
                </td>
              </FSTableRow>
            </template>
          </template>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import { getFormattedDateTime } from '@/utils/datetime'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import XStatus from '@/components/badge/XStatus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import MoreActions from '@/views/marketplace/shared/MoreActions.vue'
import { dateZuluFormatter } from '@/utils'
export default {
  name: 'ViewBuyerOrders',
  components: {
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    DateRangePicker,
    MoreActions,
  },
  data() {
    return {
      path: '',
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.orderId'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.transactionTime'
          ),
          width: '20%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.noOfVehicle'
          ),
          width: '15%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.amount'
          ),
          width: '15%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.status'
          ),
          width: '15%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.action'
          ),
          width: '10%',
          sort: 'null',
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.orderId'
          ),
          width: '10%',
          sort: 'id',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.noOfVehicle'
          ),
          width: '15%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.amount'
          ),
          width: '15%',
          sort: 'null',
        },
        {
          text: this.$t(
            'components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.status'
          ),
          width: '15%',
          sort: 'null',
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    id() {
      return this.$route.params.id
    },
    getEndpoint() {
      return MarketplaceConfig.api.marketplaceBuyerOrder(this.id)
    },
  },
  async created() {
    this.path = this.$route.path
  },
  methods: {
    onApplyFilterDateRange,
    dateZuluFormatter,
    getFormattedDateTime,
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'failed') return 'red'
      if (data === 'unpaid') return 'gray'
      return 'orange'
    },
    getItemStatus(data) {
      if (data === 'paid') return 'Paid'
      if (data === 'failed') return 'Failed'
      if (data === 'unpaid') return 'Unpaid'
      return data
    },
  },
}
</script>
<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
